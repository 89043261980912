import React, { useCallback, useState } from 'react';
// import 'App.css';

import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';



import LoginSocialFacebook from './LoginSocialFacebook/index'
import LoginSocialGoogle from './LoginSocialGoogle/index'


const REDIRECT_URI =
  'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
// const REDIRECT_URI = 'http://localhost:3000/account/login'

const App = () => {
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState();

  const onLoginStart = useCallback(() => {
    alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    alert('logout success');
  }, []);

  const onLogout = useCallback(() => { }, []);


  return (
    <>
      <div className={`App ${provider && profile ? 'hide' : ''}`}>
        <h1 className="title">ReactJS Social Login</h1>
        <LoginSocialFacebook
          appId={'1070942980593800'}
          fieldsProfile={
            'email'
          }
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          redirect_uri={REDIRECT_URI}
          onResolve={({ provider, data }) => {
            console.log('log===============' + JSON.stringify(data));
            setProvider(provider);
            setProfile(data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <FacebookLoginButton id="1111" />
        </LoginSocialFacebook>

        <LoginSocialGoogle
          client_id={'179332120407-vo94sj7a9fo5vb32sthnl9uoacbqpct2.apps.googleusercontent.com'}
          onLoginStart={onLoginStart}
          access_type="offline1"
          typeResponse='idToken'
          auto_select = {true}
          ux_mode='popup'
          onResolve={({ provider, data }) => {
            console.log('log===============' + JSON.stringify(data));
            setProvider(provider);
            setProfile(data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <GoogleLoginButton/>
        </LoginSocialGoogle>

      </div>
    </>
  );
};

export default App;